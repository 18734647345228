import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/page-header';
import PrimaryButton from '../components/atomic/PrimaryButton';

import bg from '../images/img6.jpg';
import img from '../images/img7.jpg';

const EnrollPage = () => (
  <Layout>
    <SEO title="Inscripciones" />
    <div className="page enroll-page">
      <PageHeader bg={bg} />
      <div className="wrapper content">
        <h1>Inscripciones</h1>
        <p>
          Para el Instituto Hidalgo Bilingüe es un placer otórgale la información necesaria para llevar a cabo su proceso de Admisión e Inscripción de su hijo, ya que para nosotros su tiempo es importante.
        </p>
        <p>
          Para ingresar a nuestra Institución, es necesario concertar una cita para brindarle la información necesaria para realizar su proceso de inscripción en un horario de <b>9:00 a 13:00</b> llamando al <a href="tel:5553795977">55 5379 5977</a>:
        </p>
        <div className="row">
          <div className="col col-50">
            <ol>
              <li>Presentar los siguientes Documentos:</li>
              <ul>
                <li>Acta de Nacimiento (original y copia)</li>
                <li>Boleta del año anterior (original y copia)</li>
                <li>Comprobante de Domicilio (original y copia)</li>
                <li>Copia de la Cartilla de Vacunación</li>
                <li>Copia de la CURP</li>
                <li>Copia de la identificación oficial del padre de familia</li>
                <li>Certificado Médico</li>
                <li>Carta de buena conducta</li>
                <li>Carta de no adeudo</li>
              </ul>
              <li>Llenar las solicitudes que le serán entregadas</li>
              <li>
                <b>Para poder gozar del porcentaje de descuento en su inscripción</b>, deberá realizarla en un periodo no mayor a los 5 días habiles posteriores a la entrega de la información.</li>
              <li>
                <b>La entrega de listas de útiles y libros</b> se les entregará a partir de la última semana del mes de Junio, así como información adicional de importacia para el Padre de Familia.
              </li>
            </ol>
          </div>
          <div className="col-50 img-container photo">
            <img src={img} alt=""/>
            <div className="circle" />
            <div className="circle2" />
          </div>
        </div>
        <div className="to-center col">
          <p>
            <b>Para cualquier duda referente a este proceso</b>
            <br/>
            Informes de Lunes a Viernes de 9:00 a 13:00 hrs.
          </p>
          <div className="to-center">
            <PrimaryButton to="/contacto" text="Contáctanos" icon />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default EnrollPage;